import {Form, Modal} from "@douyinfe/semi-ui";
import {Box, Button, Typography} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import moment from "moment";
import {useState} from "react";
import request from "utils/request";

const UserInvoiceRecord = ({userinfo}) => {
    const [list, setList] = useState([])
    return (
        <Box bgcolor="#fff">
            <Typography fontSize={15} mb={1}>用户发票记录</Typography>
            <Form layout="horizontal" labelPosition="inset" onSubmit={async e => {
                // url Srapp.Web_Invoice_Infos.UserInvoiceRecord
                // 获取用户发票记录信息

                // POST
                // 接口描述：

                // 接口参数
                // 参数名字	类型	是否必须	默认值	其他	说明
                // userid	整型	必须			USERID
                // begintime	日期	必须			起始时间
                // endtime	日期	必须			结束时间
                // row	整型	可选	0		单页行数
                // page	整型	可选	1		页码
                // token	字符串	必须			token
                const rew = await request('post', '/api/getInfo', {
                    url: 'Srapp.Web_Invoice_Infos.UserInvoiceRecord',
                    ...e,
                    userid: userinfo.userid
                })
                setList(rew.data)

            }}>
                {/* 开始时间 结束时间 确认按钮 */}
                <Form.Input field='begintime' initValue={'1991-01-01'} type="date" label="开始时间"
                            style={{width: 200}}/>
                <Form.Input field='endtime' initValue={moment().format('YYYY-MM-DD')} type="date" label="结束时间"
                            style={{width: 200}}/>
                <Button type="submit" variant="contained" size="small">搜索</Button>
            </Form>

            <Box mt={3} overflow="scroll" height="60vh">
                {/* 表格 */}
                {/* "[#1 - 16.3ms - SQL]/www/wwwroot/suda-2-api/src/srapp/Domain/Invoice/Info.php(50):    Srapp\\Model\\Curd\\QueryAction::QueryTable()    user_invoice_record    SELECT id,addtime,serial,name,taxnumber,addresstelephone,bankaccount,contact,remarks,invoicecode,invoicenumber,total,updatetime,goodsdetails,isp,department,operator,state FROM user_invoice_record WHERE (companyid = '101') AND (userid = 144194) AND (addtime>=?) AND (addtime<?); -- '2000-01-01', '2023-07-25'" */}
                <AgGridReact
                    className="ag-theme-balham"
                    rowData={list}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    }}
                    columnDefs={[
                        {headerName: '发票号', field: 'invoicenumber'},
                        {headerName: '发票代码', field: 'invoicecode'},
                        {headerName: '发票金额', field: 'total'},
                        {headerName: '发票类型', field: 'isp'},
                        {headerName: '推送时间', field: 'addtime'},
                        {headerName: '开票时间', field: 'updatetime'},
                        {headerName: '开票人', field: 'operator'},
                        {headerName: '开票状态', field: 'state'},
                        {headerName: '备注', field: 'remarks'},
                        {
                            headerName: '商品', field: 'goodsdetails', cellRendererFramework: ({data}) => {
                                if (data.goodsdetails) {
                                    const goodsdetails = JSON.parse(data.goodsdetails)
                                    return goodsdetails.map(item => {
                                        return <div>{item.goodsname}</div>
                                    })
                                } else {
                                    return <div></div>
                                }
                            }
                        },
                        {
                            headerName: '订单号', width: 330, field: 'goodsdetails', cellRendererFramework: ({data}) => {
                                if (data.goodsdetails) {
                                    // console.log('订单号',data.goodsdetails)

                                    let goodsdetails_arr = JSON.parse(data.goodsdetails)

                                    return goodsdetails_arr.map(item => {
                                        const str = item.goodsserials.toString()
                                        return <div>
                                            <span>{str.substr(0,6)}</span>
                                            <span style={{fontSize:30,color:'black'}}>{str.substr(6,6)}</span>
                                            <span>{str.substr(12)}</span>
                                        </div>
                                    })

                                } else {
                                    return <div></div>
                                }
                            }
                        },
                        {headerName: '开票状态', field: 'state'},
                        {
                            headerName: '操作',
                            cellRendererFramework: ({data}) => data.state == '已送达' && <>
                                <Button onClick={async () => {
                                    // isp	字符串	必须			发票提供商
                                    // invoicecode	字符串	必须			发票代码
                                    // invoicenumber	字符串	必须			发票号码
                                    // total	字符串	必须			价税合计
                                    const rew = await request('post', '/api/getInfo', {
                                        url: 'Srapp.Web_Invoice_Handle.DownloadInvoice',
                                        isp: data.isp,
                                        invoicecode: data.invoicecode,
                                        invoicenumber: data.invoicenumber,
                                        total: data.total
                                    })
                                    if (rew.data.msg == 'SUCCESS') {
                                        window.open(rew.data.info, '_blank')
                                    }
                                }} size="small">下载</Button>

                                <Button onClick={async () => {
                                    const rew = await request('post', '/api/getInfo', {
                                        url: 'Srapp.Web_Invoice_Infos.GetInvoiceInfo',
                                        userid: userinfo.userid,
                                        id: data.id
                                    })
                                    if (rew.data.message == '执行成功') {
                                        let content = rew.data.data
                                        Modal.info({
                                            title: '发票信息',
                                        // {
                                        //     "ddlsh": "17062614010284",
                                        //     "status": 2,
                                        //     "_status": "开具成功",
                                        //     "fplxdm": "02",
                                        //     "_fplxdm": "数电普票",
                                        //     "xsfNsrsbh": "91450100MA5LBMG90Y",
                                        //     "xsfMc": "南宁三燃液化气有限公司",
                                        //     "xsfDzdh": "南宁市槎路268号~~0771-2835379",
                                        //     "xsfYhzh": "工行南宁市麻村支行~~2102112409300038265",
                                        //     "gmfNsrsbh": "91450103715180236M",
                                        //     "gmfMc": "广西信达友邦资产土地房地产评估有限公司",
                                        //     "gmfDzdh": "南宁市青秀区茶花园路8号碧湖大厦11楼0771-2849801",
                                        //     "gmfYhzh": "中国建设银行股份有限公司南宁新城支行45001604254050502739",
                                        //     "gmfMobile": null,
                                        //     "gmfEmail": "48878539@qq.com",
                                        //     "gmfDz": null,
                                        //     "gmfDh": null,
                                        //     "gmfYh": null,
                                        //     "gmfZh": null,
                                        //     "xsfDz": "南宁市槎路268号",
                                        //     "xsfDh": "0771-2835379",
                                        //     "xsfYh": "工行南宁市麻村支行",
                                        //     "xsfZh": "2102112409300038265",
                                        //     "fpdm": null,
                                        //     "fphm": null,
                                        //     "kprq": "20240126173006",
                                        //     "jshj": 110,
                                        //     "hjje": 100.92,
                                        //     "hjse": 9.08,
                                        //     "mmq": null,
                                        //     "jym": null,
                                        //     "pdf": "https://dppt.guangxi.chinatax.gov.cn:8443/kpfw/fpjfzz/v1/exportDzfpwjEwm?Wjgs=PDF&Jym=464A&Fphm=24452000000006710579&Kprq=20240126173006&Czsj=1706261407431",
                                        //     "zsfs": 0,
                                        //     "_zsfs": "普通征税",
                                        //     "qdbz": 0,
                                        //     "_qdbz": "非清单",
                                        //     "tspz": "00",
                                        //     "_tspz": "普通",
                                        //     "yfpHm": null,
                                        //     "yfpDm": null,
                                        //     "tzdbh": null,
                                        //     "kce": null,
                                        //     "ycyy": null,
                                        //     "ewm": "MDEsbnVsbCxudWxsLG51bGwsMTAwLjkyLDIwMjQwMTI2LG51bGwsODlBRg==",
                                        //     "elecBz": 1,
                                        //     "_elecBz": "是",
                                        //     "sfwzzfp": "N",
                                        //     "yqdfphm": null,
                                        //     "qdfphm": "24452000000006710579",
                                        //     "zpFppzdm": null,
                                        //     "ofdurl": "https://dppt.guangxi.chinatax.gov.cn:8443/kpfw/fpjfzz/v1/exportDzfpwjEwm?Wjgs=OFD&Jym=464A&Fphm=24452000000006710579&Kprq=20240126173006&Czsj=1706261407609",
                                        //     "xmlurl": "https://dppt.guangxi.chinatax.gov.cn:8443/kpfw/fpjfzz/v1/exportDzfpwjEwm?Wjgs=XML&Jym=464A&Fphm=24452000000006710579&Kprq=20240126173006&Czsj=1706261407609",
                                        //     "gsPdf": null,
                                        //     "gsOfdUrl": null,
                                        //     "jfdz": "https://dppt.guangxi.chinatax.gov.cn:8443/v/2_24452000000006710579_202401261730060090Y464A",
                                        //     "kpr": "秦智聪",
                                        //     "skr": null,
                                        //     "fhr": null,
                                        //     "bz": "2023年11月28日",
                                        //     "detailParam": [
                                        //     {
                                        //         "fphxz": 0,
                                        //         "_fphxz": "正常行",
                                        //         "spmc": "液化石油气",
                                        //         "dw": "瓶",
                                        //         "ggxh": "12KG",
                                        //         "spbm": "1070101110100000000",
                                        //         "zxbm": null,
                                        //         "yhzcbs": 0,
                                        //         "lslbs": 0,
                                        //         "_lslbs": "正常税率",
                                        //         "zzstsgl": null,
                                        //         "sl": 1,
                                        //         "dj": 110,
                                        //         "je": 110,
                                        //         "bhsDj": 100.9174311927,
                                        //         "bhsJe": 100.92,
                                        //         "slv": 0.09,
                                        //         "se": 9.08
                                        //     }
                                        // ],
                                        //     "hwysfwdzfpmxbList": null,
                                        //     "jzfwTdys": null,
                                        //     "bdcTdys": null,
                                        //     "lkysfwTdysList": null
                                        // }
                                            content: <div>

                                                <div>发票金额: {content.jshj}</div>
                                                <div>发票类型: {content._fplxdm}</div>
                                                <div>开票时间: {content.kprq}</div>
                                                <div>开票人: {content.kpr}</div>
                                                <div>开票状态: {content._status}</div>
                                                <div>商品: {content.detailParam.map(item => {
                                                    return <div>{item.ggxh} X{item.sl}</div>

                                                })}</div>
                                                <div>订单号: {content.detailParam.map(item => {
                                                    return <div>{item.zzstsgl}</div>
                                                })}</div>
                                                <div>异常提示: {content.ycyy}</div>
                                                <div>pdf
                                                    <a style={{color: "blue"}} href={content.pdf}
                                                       target="_blank">下载</a>
                                                </div>
                                                <div>xml
                                                    <a style={{color: "blue"}} onClick={()=>{
                                                        const input = document.createElement('input');
                                                        input.value = content.xmlurl;
                                                        document.body.appendChild(input);
                                                        input.select();
                                                        document.execCommand('copy');
                                                        document.body.removeChild(input);
                                                        alert('复制成功')

                                                    }}
                                                       target="_blank">复制链接</a>
                                                    <span> | </span>
                                                    <a style={{color: "blue"}} href={content.xmlurl}
                                                       target="_blank">下载</a>
                                                </div>
                                            </div>

                                        })
                                    }
                                }} size="small">查看</Button>
                            </>
                        },
                    ]}

                />
            </Box>
        </Box>
    )

}

export default UserInvoiceRecord;