import {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Outlet, useNavigate} from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import SoftphoneComponent from "../../ui-component/Softphone";
import Softphone from '7moor-softphone-sdk';
import agentConfig from '../../utils/agentConfig'
import './index.css'
import {useLocation} from "react-router";
import moment from "moment";
import ringing from "../../assets/images/ringing.mp3";
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    // 处理软电话事件
    let timer = null
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('xl'));
    // const matchDownMd = true;

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // hasLiandian()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearInterval(timer)
        document.title = '三燃后台管理'
    }, [matchDownMd]);

    const audio = useRef(null);

    useEffect(() => {
        audio.current = new Audio(ringing);

        audio.current.oncanplaythrough = () => {
            console.log('音频加载完成');
        };

        return () => {
            audio.current.pause();
            audio.current.src = ""; // 清理源
        };
    }, []);

    const [show,setShow] = useState(false)


    const softphoneRef = useRef(null);
    const [incomingCall, setIncomingCall] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [callHistory, setCallHistory] = useState([]); // 存储通话记录
    const [seatStatus, setSeatStatus] = useState('0'); // 默认状态为“空闲”

    // 初始化软电话
    const initSoftphone = () => {
        let setno = localStorage.getItem('seatno')
        console.log('初始化软电话...');
        const config = agentConfig[setno];

        const softphone = new Softphone({
            accountId: config.accountId,
            agentNumber: config.agentNumber,
            password: config.password,
            loginType: 'sip',
            proxy_url: 'https://pbx-sh-dxali1.7moor.com',
            success: () => {
                console.log('座席初始化成功');
            },
            error: (err) => {
                console.error('座席初始化失败', err);
            }
        });

        softphone.attachEvent({
            success: () => {
                console.log('事件绑定成功');
            },
            message: (res) => {
                if (res && res.type) {
                    console.log('收到事件', res.type, res);
                    handleSoftphoneEvent(res);
                } else {
                    console.error('事件数据不完整或未定义:', res);
                }
            },
            error: (err) => {
                console.error('事件绑定失败', err);
            }
        });

        softphoneRef.current = softphone;
    };

    const [hc,sethu] = useState(false)

    const hasLiandian = () => {
        timer = setInterval(() => {
            if (new Date().getTime() % 2 === 0) {
                document.title = '您有新的来电....'
            } else {
                document.title = '.您有新的来电.......'
            }

        }, 500)
    }

    const handleSoftphoneEvent = (res) => {
        const { type, eventData,typeValue } = res;

        switch (type) {
            case 'belling':
                // setShow(true)
                setBeling(true)
                audio.current.play().catch(err => {
                    console.error('播放失败:', err);
                });
                let phoneNumber = (eventData.FromCid).toString().replace('+86771','').replace(/^771/,'')
                // navigate('/users/VagueQueryUserInfo?tel=' + eventData.FromCid)
                setPhoneNumber(phoneNumber)

                setIncomingCall(true); // 标记有来电
                hasLiandian()

                // 记录来电号码到本地 时间 电话 数组形式 倒序排序
                const call_arr = JSON.parse(localStorage.getItem('call_arr')) || []
                // 加在数组最前面
                call_arr.unshift({
                    time: moment().format('YYYY-MM-DD HH:mm:ss'),
                    tel: phoneNumber
                })
                localStorage.setItem('call_arr', JSON.stringify(call_arr))
                // setldtel(eventData.FromCid)
                localStorage.setItem('ldtel', phoneNumber)
                //最多记录100条 然后删除第一条 重新保存到本地
                if (call_arr.length > 100) {
                    call_arr.pop()
                    localStorage.setItem('call_arr', JSON.stringify(call_arr))

                }
                break;
            case 'dialing':
                console.log('呼叫中...', type, eventData);
                hasLiandian()
                break;
            case 'innerTalking':
            case 'dialTalking':
                console.log('通话开始');
                setBeling(false)
                audio.current.pause();
                // updateCallHistory('呼出', eventData);
                // handleTalkingEvent('呼出',eventData);
                handleTalkingEvent(eventData.ChannelType, eventData)
                sethu(true); // 来电标记取消
                clearInterval(timer)
                document.title = '三燃后台管理'
                break;
            case 'hangup':
                console.log('通话结束');
                setIncomingCall(false); // 来电标记取消
                sethu(false); // 来电标记取消
                clearInterval(timer)
                document.title = '三燃后台管理'
                // updateCallHistory('呼入', eventData);
                break;
            case 'peerstate':
                handlePeerStateEvent(eventData); // 处理 peerstate 事件
                setSeatStatus(typeValue)
                break;
            case 'talking': // 呼入电话的录音事件
                            // handleTalkingEvent('呼入',eventData);
                handleTalkingEvent(eventData.LinkedChannel.ChannelType, eventData.LinkedChannel)
                break;
            default:
                console.log('未知事件类型:', type);
        }
    };

    // 处理 talking 事件
    const handleTalkingEvent = (type,eventData) => {
        const monitorUrl = eventData?.Data?.MonitorFilename;
        if (monitorUrl) {
            updateCallHistory(type, eventData, monitorUrl);
        }
    };

    // 处理 peerstate 事件
    const handlePeerStateEvent = (eventData) => {
        if (eventData.ChannelStatus === 'Hangup') {
            console.log('用户挂断电话:', eventData);
            setIncomingCall(false); // 来电标记取消
            sethu(false)
            setBeling(false)
            audio.current.pause();
            clearInterval(timer)
            document.title = '三燃后台管理'
            changeSeatStatus(1)
            // setTimeout(() => {
            //     changeSeatStatus(0)
            // },5000)
            // updateCallHistory('呼出', eventData); // 添加呼出记录


        }
        if (eventData.Event === "UserBusy") {
            console.log('设置坐席状态：', eventData.BusyType);
            setSeatStatus(eventData.BusyType);
        }
    };

    // 更新通话记录
    const updateCallHistory = (type, eventData, monitorUrl = '无录音') => {
        const newRecord = {
            type,
            from: eventData.FromCid || eventData.Data.ListenExten,
            to: eventData.FromDid || eventData.Exten,
            time: new Date().toLocaleString(),
            monitor: monitorUrl
        };
        setCallHistory(prevHistory => [newRecord, ...prevHistory]);
    };

    // 接听电话
    const acceptCall = () => {
        if (softphoneRef.current && softphoneRef.current.webrtcApi) {
            audio.current.pause();
            softphoneRef.current.webrtcApi.answer({
                success: () => {

                    console.log('成功接听');
                    setIncomingCall(false);
                    sethu(false)
                },
                fail: (err) => {
                    console.error('接听失败', err);
                }
            });
        }
    };

    // 外呼功能
    const makeCall = () => {
        if (softphoneRef.current && softphoneRef.current.callApi) {
            softphoneRef.current.callApi.dialout({
                calleeNumber: phoneNumber,
                success: () => {
                    console.log('外呼成功');
                    // updateCallHistory('呼出', { FromCid: phoneNumber, Data: {} });
                },
                fail: (err) => console.error('外呼失败', err)
            });
        } else {
            console.warn('软电话未初始化');
        }
    };

    // 切换坐席状态
    const changeSeatStatus = (status) => {
        if (softphoneRef.current && softphoneRef.current.agentApi) {
            softphoneRef.current.agentApi.updateAgentStatus({
                statusValue: status,
                // success: (res) => {
                //     if (res.success) {
                //         console.log('坐席状态更新成功:', status);
                //         setSeatStatus(status);
                //     } else {
                //         console.error('坐席状态更新失败');
                //     }
                // },
                // fail: (err) => {
                //     console.error('坐席状态更新失败:', err);
                // }
            });
        }
    };

    const setnolist = [101, 102, 104, 105, 107, 110, 111, 112, 113, 114, 115, 116,109]
    const login_seatno = localStorage.getItem('seatno')
    const url = useLocation();
    useEffect(() => {
        // console.log('url.path',url.path)

        if (login_seatno && setnolist.includes(Number(login_seatno)) &&  (url.pathname === '/' || url.pathname === '/dashboard/default')) {
            initSoftphone();
            return () => {
                if (softphoneRef.current) {
                    softphoneRef.current = null;
                }
            };
        }

    }, []);


    const [isbeling, setBeling] = useState(false);
    // 根据状态设置颜色
    const getStatusClass = () => {
        switch (seatStatus) {
            case '0': return 'status-idle'; // 空闲
            case '1': return 'status-busy'; // 忙碌
            case '2': return 'status-break'; // 小休
            default: return '';
        }
    };



    return (
        <>

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                 {/*header */}
                {/*<button onClick={()=>{*/}
                {/*    audio.current.play().catch(err => {*/}
                {/*        console.error('播放失败:', err);*/}
                {/*    });*/}
                {/*}}>播放</button>*/}
                {/*<button onClick={()=>{*/}
                {/*    audio.current.pause()*/}
                {/*}}>暂停</button>*/}
                {
                    !show ?

                        (login_seatno && setnolist.includes(Number(login_seatno)) && (url.pathname === '/' || url.pathname === '/dashboard/default')) ?

                        <div className={isbeling?'belingchange':''} onClick={()=> {
                            setShow(true)
                            setBeling(false)
                            audio.current.pause();
                        }} style={{
                        position: 'fixed',
                        bottom: 100,
                        right: 5,
                        zIndex: 99999,
                        background: 'white',
                        borderRadius: '50%',
                        padding: '10px',
                        border: '1px soild #000'
                    }}>
                        <svg t="1724896719371" className="icon" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="2991" width="32" height="32">
                            <path
                                d="M810.666667 584.533333l-4.266667-4.266666-12.8-93.866667c-4.266667-21.333333-21.333333-38.4-42.666667-38.4H302.933333c8.533333-25.6 8.533333-55.466667 0-85.333333l-8.533333-34.133334L682.666667 221.866667l8.533333 38.4c8.533333 38.4 34.133333 72.533333 72.533333 93.866666 34.133333 21.333333 76.8 25.6 115.2 17.066667 76.8-21.333333 119.466667-93.866667 110.933334-183.466667-8.533333-51.2-38.4-119.466667-115.2-149.333333C716.8-25.6 550.4 4.266667 426.666667 38.4 320 64 128 132.266667 34.133333 264.533333c-38.4 55.466667-38.4 136.533333-21.333333 183.466667 25.6 68.266667 85.333333 106.666667 145.066667 106.666667 12.8 0 25.6 0 38.4-4.266667 25.6-8.533333 51.2-21.333333 68.266666-38.4L256 580.266667l-4.266667 4.266666c-123.733333 68.266667-200.533333 196.266667-200.533333 337.066667V981.333333c0 25.6 17.066667 42.666667 42.666667 42.666667h878.933333c25.6 0 42.666667-17.066667 42.666667-42.666667v-59.733333c0-140.8-81.066667-268.8-204.8-337.066667zM200.533333 307.2l21.333334 81.066667c12.8 38.4-8.533333 72.533333-46.933334 85.333333-38.4 12.8-72.533333-17.066667-85.333333-51.2-8.533333-25.6-8.533333-76.8 12.8-106.666667 59.733333-81.066667 187.733333-153.6 345.6-196.266666 170.666667-46.933333 290.133333-46.933333 396.8 0 38.4 17.066667 59.733333 51.2 64 81.066666 4.266667 34.133333-8.533333 76.8-46.933333 89.6-17.066667 4.266667-34.133333 4.266667-51.2-8.533333-17.066667-8.533333-25.6-21.333333-29.866667-42.666667l-21.333333-81.066666c-4.266667-12.8-8.533333-21.333333-21.333334-25.6-12.8-4.266667-21.333333-4.266667-34.133333-4.266667L230.4 256c-21.333333 8.533333-34.133333 29.866667-29.866667 51.2zM930.133333 938.666667H136.533333v-17.066667c0-110.933333 59.733333-209.066667 157.866667-264.533333l12.8-8.533334c17.066667-8.533333 29.866667-25.6 34.133333-46.933333l8.533334-64h366.933333l8.533333 68.266667c4.266667 21.333333 17.066667 38.4 34.133334 51.2l12.8 8.533333c98.133333 51.2 157.866667 153.6 157.866666 264.533333v8.533334z"
                                fill="#374151" p-id="2992"></path>
                            <path
                                d="M533.333333 917.333333c-89.6 0-166.4-72.533333-166.4-166.4 0-89.6 72.533333-166.4 166.4-166.4s166.4 72.533333 166.4 166.4c-4.266667 89.6-76.8 166.4-166.4 166.4z m0-247.466666c-42.666667 0-81.066667 34.133333-81.066666 81.066666s34.133333 81.066667 81.066666 81.066667 81.066667-34.133333 81.066667-81.066667-38.4-81.066667-81.066667-81.066666z"
                                fill="#374151" p-id="2993"></path>
                        </svg>
                    </div> : ''
                        :
                        <div style={{
                            position: 'fixed',
                            top: 100,
                            left: '40%',
                            zIndex: 99999,
                        }}>
                            <SoftphoneComponent
                                acceptCall={acceptCall}
                                softphoneRef={softphoneRef}
                                seatStatus={seatStatus}
                                getStatusClass={getStatusClass}
                                changeSeatStatus={changeSeatStatus}
                                setPhoneNumber={setPhoneNumber}
                                setShow={setShow}
                                phoneNumber={phoneNumber}
                                callHistory={callHistory}
                                makeCall={makeCall}
                                incomingCall={incomingCall}
                                hc={hc}
                            />
                        </div>
                }

                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar>
                        <Header handleLeftDrawerToggle={handleLeftDrawerToggle}/>

                    </Toolbar>

                </AppBar>

                {/* drawer */}
                <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle}/>

                {/* main content */}
                <Main theme={theme} open={leftDrawerOpened}>
                    {/* breadcrumb */}
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign/>
                    <div className={'maincontent'} style={{
                        padding: '10px',
                        borderRadius: 3,
                        overflow: 'scroll',
                        height: '90vh',
                        marginBottom: '3vh',
                        backgroundColor: '#fff'
                    }}>
                        <Outlet/>
                    </div>


                </Main>
                <Customization/>


            </Box>

        </>
    );
};

export default MainLayout;
